<template>
  <div class="notice">
    <Header :title="type|filterPeriods" :back="true"/>
    <div v-if="num!=0">
      <div class="box" v-for="item in msgList" :key="item.id" @click="getInfo(item.key)">
        <div class="time">{{item.addtime}}</div>
        <div class="box-items">
          <div class="box-title theme-color">{{item.title}}</div>
          <div class="box-content">{{item.content}}</div>
        </div>
      </div>
    </div>
    <div v-else class="emp">
      <el-empty :description="noMsg"></el-empty>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  filters:{
    filterPeriods(type){
      const statusDay ={
        deal:'交易动态',
        cash:'充值转出',
        activity:'优惠活动',
        notice:'系统公告'
      }
      return statusDay[type]
    }
  },
  data(){
    return{
      num:0,
      msgList:[],
      noMsg:'',
      type:this.$route.query.type
    }
  },
  created(){
    this.noMsg="暂无"+this.$options.filters.filterPeriods(this.type)
    this.getMessage()
  },
  methods:{
    getMessage(){
      this.$store.dispatch('auth/getMessage',{params:{type:this.type}}).then(res => {
        this.num =res.messages.length
        this.msgList=res.messages
      })
    },
    getInfo(key){
      if(this.type=='deal'||this.type=='cash'){
        this.$router.push({path:'paysuccess',query:{
          key:key
        }})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.notice{
  background: #F8F8F8;
  padding-bottom:1rem;
  .emp{
    margin-top: 100px;
  }
  .box{
    margin-top:1rem;
    margin-bottom: 2rem;
    .time{
      text-align: center;
      color: #464646;
      margin-bottom:5px;
    }
  }
  .box-title{
    line-height: 2;
  }
  .box-content{
    font-size: .9rem;
    color: #464646;
    line-height: 1.5;
  }
}
</style>