var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notice" },
    [
      _c("Header", {
        attrs: { title: _vm._f("filterPeriods")(_vm.type), back: true },
      }),
      _vm.num != 0
        ? _c(
            "div",
            _vm._l(_vm.msgList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "box",
                  on: {
                    click: function ($event) {
                      return _vm.getInfo(item.key)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "time" }, [
                    _vm._v(_vm._s(item.addtime)),
                  ]),
                  _c("div", { staticClass: "box-items" }, [
                    _c("div", { staticClass: "box-title theme-color" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("div", { staticClass: "box-content" }, [
                      _vm._v(_vm._s(item.content)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            { staticClass: "emp" },
            [_c("el-empty", { attrs: { description: _vm.noMsg } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }